import React from "react";
import { useDispatch } from "react-redux";
import "./style.scss";
import { NavLink } from "react-router-dom"; // Import NavLink instead of Link
import { LoginValue } from "../../Actions/LoginPageAction";

const SideBar = () => {
  const dispatch = useDispatch();

  const logoutWrapper = () => {
    localStorage.removeItem("token");
    dispatch(LoginValue(false));
    window.location.reload();
  };

  return (
    <div className="SideBar_wrapper">
      <ul>
        <li>
          <NavLink to="/dashboard" activeClassName="active">
            <span className="browse"></span>
            <span>Dashboard</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/floors" activeClassName="active">
            <span className="floor"></span>
            <span>Floors</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/floorManagement" exact activeClassName="active">
            <span className="floormangement"></span>
            <span>Floor Management</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/device" activeClassName="active">
            <span className="proj"></span>
            <span>Devices</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/history" activeClassName="active">
            <span className="browse_activity"></span>
            <span>History</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/analytics" activeClassName="active">
            <span className="monitoring"></span>
            <span>Analytics</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/help" activeClassName="active">
            <span className="help"></span>
            <span>Help</span>
          </NavLink>
        </li>
        <li>
          <a style={{ cursor: "pointer" }} onClick={logoutWrapper}>
            <span className="logout"></span>
            <span>Logout</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
