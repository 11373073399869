import React from 'react'
import './style.scss'
import map from '../../assets/images/map.svg' 
const Header = () =>{
    return(
        <div className="footer_wrapper">
            <ul className="leftMenu">
                <li><a className="map"></a></li>
                <li><a className="list"></a></li>
            </ul>
            <ul className="centerMenu">
                <li><a className="strobe-Button"></a><span>Strobe</span></li>
                <li><a className="Push-Button"></a><span>Push Button</span></li>
                <li><a className="Speaker-Button"></a><span>Speaker</span></li>
                <li><a className="Camera-Button"></a><span>Camera</span></li>
                <li><a className="repair-Button"></a><span>Need Repair</span></li>
            </ul>
            <ul className="rightMenu">
                <li><a className="zoom_in"></a></li>
                <li><a className="zoom_out"></a></li>
                <li><a className="fullscreen"></a></li>
            </ul>
        </div>
    )
}

export default Header