import React from 'react'

const FloorManagement = () => {
    return (
        <div>
            FloorManagement
        </div>
    )
}

export default FloorManagement
