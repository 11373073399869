import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import axios from "axios";
import { LoginValue } from "../../Actions/LoginPageAction";
import { Validation } from '../../Validation/Validation';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import './style.scss';

const ForgotPassword = () => {
    const [formData, setFormData] = useState({ username: '', password: '' });  // Single state object for both fields
    const [formError, setFormError] = useState({ usernameError: '', passwordError: '' });  // Single state object for errors
    const dispatch = useDispatch();

    // Generalized input change handler
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Validation function
    // const handleValidation = () => {
    //     let isValid = true;
    //     const errors = { usernameError: '', passwordError: '' };

    //     if (Validation.empty(formData.username)) {
    //         errors.usernameError = "Please enter your username";
    //         isValid = false;
    //     }

    //     if (Validation.empty(formData.password)) {
    //         errors.passwordError = "Please enter your password";
    //         isValid = false;
    //     }

    //     setFormError(errors);
    //     return isValid;
    // };

    // Submit handler
    const handleSubmit = async (e) => {
        // e.preventDefault();
        // if (!handleValidation()) return;

        // try {
        //     const encodedPassword = btoa(formData.password);
        //     const url = `https://lockdownexperts.biz/api/match-code?code=${encodedPassword}`;
        //     const response = await axios.get(url);

        //     if (response.status === 200) {
        //         localStorage.setItem("userData", JSON.stringify(response.status));
        //         dispatch(LoginValue(true));
        //         setFormData({ username: '', password: '' });  // Clear form
        //     } else if (response.status === 400) {
        //         setFormError({ ...formError, passwordError: "Invalid password" });
        //     }
        // } catch (error) {
        //     console.error("Error making request:", error);
        //     setFormError({ ...formError, passwordError: "Invalid password" });
        // }
    };

    return (
        <div className="right-section">
            <div className="login-form">
                <form className="field" onSubmit={handleSubmit}>
                    <Box component="form" noValidate autoComplete="off" className="field">
                        <TextField
                            id="username"
                            name="username"  // Match name to state key
                            label="Email/Username"
                            variant="outlined"
                            onChange={handleInputChange}
                            value={formData.username}
                            error={!!formError.usernameError}
                            helperText={formError.usernameError}
                            InputLabelProps={{
                                shrink: true,  // Disable floating animation
                                style: { 
                                    transform: 'inherit', 
                                },  // Custom label style
                            }}
                            InputProps={{
                                style: { borderRadius: '4px' },  // Custom input style
                            }}

                        />
                    </Box>
                    <button type="submit" className="btnSubmit">Sign in</button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
