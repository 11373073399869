import React from 'react'

const DevicesComp = () => {
    return (
        <div>
            DevicesComp
        </div>
    )
}

export default DevicesComp
