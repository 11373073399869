import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import './style.scss'
import {Container, Row, Col} from 'react-bootstrap';
import SigninPage from "./SigninPage"
import ForgotPassword from "./ForgotPassword"
import {useLocation} from "react-router-dom";

const LoginPage = () =>{
    const location = useLocation();
    const isForgotPassword = location.pathname.includes("/ForgotPassword");
    const renderForm = () => {
        return isForgotPassword ? <ForgotPassword /> : <SigninPage />;
      };
      
    return(
        <>
        <div className={`Login_container ${isForgotPassword ? "forgetPage" : "mainLoginPage"}`}>
            <Container>
                <div className="header">
                   <Row>
                   <Col xl={6}><div className="logoImg"></div></Col>
                   <Col xl={6}>
                        <div className="needAndsupport">
                            <a href="#">Need Assistance? Help & Support</a>
                        </div>
                   </Col>
                   </Row>
                </div>
                <div className="middleContainer">
                    <Row>
                        <Col xl={7}>
                            <div class="left-section">
                                <div class="branding">
                                    <p class="tagline">Developed by experts. <span class="highlight">Powered by AI.</span></p>
                                    <p class="description">Reduce the risk of gun violence incidents. Secure people in public buildings and spaces with proven, patented technologies and training solutions developed by experts and fueled by AI Threat Intelligence.</p>
                                    <button class="know-more-btn">Know Now</button>
                                </div>
                            </div>
                        </Col>
                        <Col xl={5}>
                            {renderForm()}
                        </Col> 
                    </Row>
                </div>
            </Container>
        </div>
        <footer>
            <Container>
            <Row>
                <Col xl={6}><p className="left">Lockdown. Save lives.</p></Col>
                <Col xl={6}><p className="right">&copy; 2024 Lockdown Experts, LLC. All rights reserved.</p></Col>
            </Row>
        </Container>
        </footer>
    </>
    )
}

export default LoginPage