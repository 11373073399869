import React from 'react'
import './style.scss'
import logo from '../../assets/images/logo.svg'
import home_work from '../../assets/images/home_work.svg'
import crisis_alert from '../../assets/images/crisis_alert.svg'
import directions_run from '../../assets/images/directions_run.svg'
import dark_mode from '../../assets/images/dark_mode.svg'
import account_circle from '../../assets/images/account_circle.svg'
import { Link } from "react-router-dom";


const Header = () => {
    const openDevicesInNewWindow = () => {
        window.open('/devices', '_blank', 'noopener,noreferrer');
    };
    return (
        <div className="header_wrapper">
            <div className="logo">
                <Link to="/"><img alt="Logo" src={logo} /></Link>
            </div>
            <div className="navbar">
                <nav>
                    <ul>
                        <li>
                        <Link to="/"><img alt="img" src={home_work} /></Link>
                        </li>
                        <li>
                        {/* <Link to="#"> <img alt="img" src={crisis_alert} /></Link> */}
                          <a href="/devices" target="_blank" rel="noopener noreferrer">
                                <img alt="img" src={crisis_alert} />
                            </a>
                        </li>
                        <li>
                        <Link to="#"><img alt="img" src={directions_run} /></Link>
                        </li>
                        <li>
                        <Link to="#"><img alt="img" src={dark_mode} /></Link>
                        </li>
                        <li>
                        <Link to="#"><img alt="img" src={account_circle} /></Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

export default Header